@keyframes animationFloatDown {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes animationFloatUp {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-60px);
    }

    100% {
        transform: translateY(0px);
    }
}


.animationFloatDown {
    animation: animationFloatDown 9s infinite;
    scale: 1;
    margin-left: 0%;
}

.animationFloatDownScale {
    @extend .animationFloatDown;
    scale: 1.1;
    margin-left: -5%;
}

.animationFloatUp {
    animation: animationFloatUp 12s infinite ease-in-out;
    scale: 1;
    margin-left: 0%;
}

.animationFloatUpScale {
    @extend .animationFloatUp;
    scale: 1.1;
    margin-left: -5%;
}