.flag { 
    display: inline-block;
    background: url('../../../assets/images/riders/flag_16_16_0_8.png');  
    width: 16px;
    height: 14px;
    transform: scale(1.4);
} 

 .flag.it{background-position:-48px -0px; } .flag.nl{background-position:-96px -0px; }          .flag.be{background-position:-144px -0px; }     .flag.es{background-position:-192px -0px; }     .flag.fr{background-position:-240px -0px; }
 .flag.gb{background-position:-288px -0px; } .flag.co{background-position:-336px -0px; }        .flag.dk{background-position:-384px -0px; }     .flag.au{background-position:-432px -0px; }     .flag.de{background-position:-0px -48px; }
 .flag.si{background-position:-48px -48px; } .flag.no{background-position:-96px -48px; }        .flag.pl{background-position:-144px -48px; }    .flag.sk{background-position:-192px -48px; }    .flag.us{background-position:-240px -48px; }
 .flag.ie{background-position:-288px -48px; } .flag.ru{background-position:-336px -48px; }      .flag.ch{background-position:-384px -48px; }    .flag.at{background-position:-432px -48px; }    .flag.pt{background-position:-0px -96px; }
 .flag.cz{background-position:-48px -96px; } .flag.nz{background-position:-96px -96px; }        .flag.lu{background-position:-144px -96px; }    .flag.ca{background-position:-192px -96px; }    .flag.za{background-position:-240px -96px; }
 .flag.kz{background-position:-288px -96px; } .flag.ee{background-position:-336px -96px; }      .flag.ec{background-position:-384px -96px; }    .flag.by{background-position:-432px -96px; }    .flag.lv{background-position:-0px -144px; }
 .flag.ua{background-position:-48px -144px; } .flag.er{background-position:-96px -144px; }      .flag.ar{background-position:-144px -144px; }   .flag.jp{background-position:-192px -144px; }   .flag.ro{background-position:-240px -144px; }
 .flag.ve{background-position:-288px -144px; } .flag.cr{background-position:-336px -144px; }    .flag.hr{background-position:-384px -144px; }   .flag.rw{background-position:-432px -144px; }   .flag.ir{background-position:-0px -192px; }
 .flag.dz{background-position:-48px -192px; } .flag.tr{background-position:-96px -192px; }      .flag.lt{background-position:-144px -192px; }   .flag.se{background-position:-192px -192px; }   .flag.hk{background-position:-240px -192px; }
 .flag.mx{background-position:-288px -192px; } .flag.ma{background-position:-336px -192px; }    .flag.br{background-position:-384px -192px; }   .flag.my{background-position:-432px -192px; }   .flag.id{background-position:-0px -240px; }
 .flag.md{background-position:-48px -240px; } .flag.et{background-position:-96px -240px; }      .flag.kr{background-position:-144px -240px; }   .flag.th{background-position:-192px -240px; }   .flag.gt{background-position:-240px -240px; }
 .flag.gr{background-position:-288px -240px; } .flag.rs{background-position:-336px -240px; }    .flag.bg{background-position:-384px -240px; }   .flag.cn{background-position:-432px -240px; }   .flag.az{background-position:-0px -288px; }
 .flag.ae{background-position:-48px -288px; } .flag.uy{background-position:-96px -288px; }      .flag.al{background-position:-144px -288px; }   .flag.cu{background-position:-192px -288px; }   .flag.mn{background-position:-240px -288px; }
 .flag.la{background-position:-288px -288px; } .flag.ke{background-position:-336px -288px; }    .flag.hu{background-position:-384px -288px; }   .flag.na{background-position:-432px -288px; }   .flag.sg{background-position:-0px -336px; }
 .flag.il{background-position:-48px -336px; } .flag.ph{background-position:-96px -336px; }      .flag.mk{background-position:-144px -336px; }   .flag.tn{background-position:-192px -336px; }   .flag.mu{background-position:-240px -336px; }
 .flag.fi{background-position:-288px -336px; } .flag.pa{background-position:-336px -336px; }    .flag.in{background-position:-384px -336px; }   .flag.bm{background-position:-432px -336px; }   .flag.lb{background-position:-0px -384px; }
 .flag.bz{background-position:-48px -384px; } .flag.cy{background-position:-96px -384px; }      .flag.pr{background-position:-144px -384px; }   .flag.kw{background-position:-192px -384px; }   .flag.xk{background-position:-240px -384px; }
 .flag.ge{background-position:-288px -384px; } .flag.cl{background-position:-336px -384px; }    .flag.cm{background-position:-384px -384px; }   .flag.do{background-position:-432px -384px; }   .flag.ci{background-position:-0px -432px; }
 .flag.me{background-position:-48px -432px; } .flag.bf{background-position:-96px -432px; }      .flag.sv{background-position:-144px -432px; }   .flag.tw{background-position:-192px -432px; }   .flag.sy{background-position:-240px -432px; }
 .flag.pk{background-position:-288px -432px; } .flag.cw{background-position:-336px -432px; }    .flag.ao{background-position:-384px -432px; }   .flag.uz{background-position:-432px -432px; }   .flag.bw{background-position:-0px -480px; }
 .flag.bv{background-position:-48px -480px; } .flag.pe{background-position:-96px -480px; }      .flag.ck{background-position:-144px -480px; }   .flag.gi{background-position:-192px -480px; }   .flag.lr{background-position:-240px -480px; }
 .flag.nr{background-position:-288px -480px; } .flag.vn{background-position:-336px -480px; }    .flag.pw{background-position:-384px -480px; }   .flag.xx{background-position:-432px -480px; }   .flag.sb{background-position:-0px -528px; }
 .flag.tc{background-position:-48px -528px; } .flag.gg{background-position:-96px -528px; }      .flag.cg{background-position:-144px -528px; }   .flag.ht{background-position:-192px -528px; }   .flag.tm{background-position:-240px -528px; }
 .flag.ng{background-position:-288px -528px; } .flag.sz{background-position:-336px -528px; }    .flag.cv{background-position:-384px -528px; }   .flag.fk{background-position:-432px -528px; }   .flag.gw{background-position:-0px -576px; }
 .flag.tz{background-position:-48px -576px; } .flag.mh{background-position:-96px -576px; }      .flag.nc{background-position:-144px -576px; }   .flag.zw{background-position:-192px -576px; }   .flag.bo{background-position:-240px -576px; }
 .flag.pn{background-position:-288px -576px; } .flag.bn{background-position:-336px -576px; }    .flag.sj{background-position:-384px -576px; }   .flag.am{background-position:-432px -576px; }   .flag.eg{background-position:-0px -624px; }
 .flag.vu{background-position:-48px -624px; } .flag.mc{background-position:-96px -624px; }      .flag.tl{background-position:-144px -624px; }   .flag.jo{background-position:-192px -624px; }   .flag.li{background-position:-240px -624px; }
 .flag.sc{background-position:-288px -624px; } .flag.aq{background-position:-336px -624px; }    .flag.cc{background-position:-384px -624px; }   .flag.sa{background-position:-432px -624px; }   .flag.pf{background-position:-0px -672px; }
 .flag.sd{background-position:-48px -672px; } .flag.ki{background-position:-96px -672px; }      .flag.fm{background-position:-144px -672px; }   .flag.nf{background-position:-192px -672px; }   .flag.om{background-position:-240px -672px; }
 .flag.pm{background-position:-288px -672px; } .flag.mt{background-position:-336px -672px; }    .flag.tk{background-position:-384px -672px; }   .flag.aw{background-position:-432px -672px; }   .flag.cs{background-position:-0px -720px; }
 .flag.kn{background-position:-48px -720px; } .flag.gn{background-position:-96px -720px; }      .flag.lc{background-position:-144px -720px; }   .flag.sr{background-position:-192px -720px; }   .flag.ls{background-position:-240px -720px; }
 .flag.mw{background-position:-288px -720px; } .flag.cd{background-position:-336px -720px; }    .flag.io{background-position:-384px -720px; }   .flag.qa{background-position:-432px -720px; }   .flag.dm{background-position:-0px -768px; }
 .flag.lk{background-position:-48px -768px; } .flag.gl{background-position:-96px -768px; }      .flag.mg{background-position:-144px -768px; }   .flag.af{background-position:-192px -768px; }   .flag.np{background-position:-240px -768px; }
 .flag.ye{background-position:-288px -768px; } .flag.bj{background-position:-336px -768px; }    .flag.ps{background-position:-384px -768px; }   .flag.so{background-position:-432px -768px; }   .flag.as{background-position:-0px -816px; }
 .flag.tv{background-position:-48px -816px; } .flag.bd{background-position:-96px -816px; }      .flag.im{background-position:-144px -816px; }   .flag.cf{background-position:-192px -816px; }   .flag.hn{background-position:-240px -816px; }
 .flag.vi{background-position:-288px -816px; } .flag.kh{background-position:-336px -816px; }    .flag.dj{background-position:-384px -816px; }   .flag.td{background-position:-432px -816px; }   .flag.fo{background-position:-0px -864px; }
 .flag.hm{background-position:-48px -864px; } .flag.tt{background-position:-96px -864px; }      .flag.mq{background-position:-144px -864px; }   .flag.ne{background-position:-192px -864px; }   .flag.re{background-position:-240px -864px; }
 .flag.tj{background-position:-288px -864px; } .flag.bh{background-position:-336px -864px; }    .flag.wf{background-position:-384px -864px; }   .flag.gh{background-position:-432px -864px; }   .flag.vc{background-position:-0px -912px; }
 .flag.mo{background-position:-48px -912px; } .flag.ni{background-position:-96px -912px; }      .flag.bt{background-position:-144px -912px; }   .flag.py{background-position:-192px -912px; }   .flag.km{background-position:-240px -912px; }
 .flag.sn{background-position:-288px -912px; } .flag.tf{background-position:-336px -912px; }    .flag.kp{background-position:-384px -912px; }   .flag.tg{background-position:-432px -912px; }   .flag.ms{background-position:-0px -960px; }
 .flag.mp{background-position:-48px -960px; } .flag.ws{background-position:-96px -960px; }      .flag.ba{background-position:-144px -960px; }   .flag.to{background-position:-192px -960px; }   .flag.bb{background-position:-240px -960px; }
 .flag.je{background-position:-288px -960px; } .flag.gy{background-position:-336px -960px; }    .flag.ag{background-position:-384px -960px; }   .flag.iq{background-position:-432px -960px; }   .flag.gu{background-position:-0px -1008px; }
 .flag.is{background-position:-48px -1008px; } .flag.st{background-position:-96px -1008px; }    .flag.vg{background-position:-144px -1008px; }  .flag.gq{background-position:-192px -1008px; }  .flag.sl{background-position:-240px -1008px; }
 .flag.gp{background-position:-288px -1008px; } .flag.mv{background-position:-336px -1008px; }  .flag.ug{background-position:-384px -1008px; }  .flag.an{background-position:-432px -1008px; }  .flag.zm{background-position:-0px -1056px; }
 .flag.pg{background-position:-48px -1056px; } .flag.gs{background-position:-96px -1056px; }    .flag.ad{background-position:-144px -1056px; }  .flag.um{background-position:-192px -1056px; }  .flag.kg{background-position:-240px -1056px; }
 .flag.ga{background-position:-288px -1056px; } .flag.bi{background-position:-336px -1056px; }  .flag.jm{background-position:-384px -1056px; }  .flag.yu{background-position:-432px -1056px; }  .flag.ly{background-position:-0px -1104px; }
 .flag.ky{background-position:-48px -1104px; } .flag.ml{background-position:-96px -1104px; }    .flag.mz{background-position:-144px -1104px; }  .flag.ai{background-position:-192px -1104px; }  .flag.cx{background-position:-240px -1104px; }
 .flag.gf{background-position:-288px -1104px; } .flag.sm{background-position:-336px -1104px; }  .flag.va{background-position:-384px -1104px; }  .flag.yt{background-position:-432px -1104px; }  .flag.nu{background-position:-0px -1152px; }
 .flag.sh{background-position:-48px -1152px; } .flag.mr{background-position:-96px -1152px; }    .flag.gm{background-position:-144px -1152px; }  .flag.bs{background-position:-192px -1152px; }  .flag.eh{background-position:-240px -1152px; }
 .flag.gd{background-position:-288px -1152px; } .flag.mm{background-position:-336px -1152px; }  .flag.fj{background-position:-384px -1152px; }  .flag.sx{background-position:-432px -1152px; }